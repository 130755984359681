.actionHover:hover {
  background: #ececec !important;
  border-radius: 20px;
}

.actionHover:hover svg {
  color: #525356 !important;
}

.d-flex.align-items-center.permissions-actions {
  margin-left: -8px;
}

.actionHover,
.disableView {
  height: 32.4px;
  width: 32.4px;
  padding: 6px 8px;
}

.deleteActionHover svg {
  margin-left: 5px;
}

.backbutton {
  border: none;
  background: no-repeat;
}

.backbutton:hover {
  background: #f0f0f0a8;
}

.dashboardBoxes {
  height: auto;
  margin: 15px;
  min-width: 110px;
  border-radius: 15px;
  padding: 10px;
  color: white;
}

.dashboardBoxes p {
  font-size: 14px;
  margin-top: 10px !important;
}

/* .react-dataTable header, */
.react-dataTable nav:last-child {
  display: none;
}

.dashboardBoxesDetails b {
  font-size: 20px;
}

.myBackGround {
  background: white;
  margin: 15px;
  border-radius: 8px;
  padding: 10px;
}

.graphMargin {
  margin: 0 15px;
}

.statusGraphMargin {
  margin: 0 30px 0 0;
}

.invalid-feedback,
.text-danger {
  text-transform: capitalize;
}

.menu-collapsed .collapsed .navbar-header .navbar-brand .brand-logo img {
  max-width: 55px;
  margin-left: -10px;
  transition:
    max-width 0.3s ease-in-out,
    margin-left 0.3s ease-in-out;
}

a#no-renderer-download {
  min-width: 200px !important;
  height: 45px !important;
  background-color: #fff !important;
  margin-top: 15px !important;
}

div#no-renderer {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}

.header__sec2-btn {
  height: 35px !important;
  width: auto !important;
  display: grid;
  align-self: center;
  vertical-align: middle;
  line-height: 9px !important;
  text-align: center;
}

#search-permission,
.inputHeight {
  height: 35px;
}

.dark-layout header {
  background: #283046;
  border-radius: 8px;
}

.dark-layout .myBackGround {
  background: #283046;
  margin: 15px;
  border-radius: 8px;
  padding: 10px;
}

.dark-layout .test {
  background-color: #161d31;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.24);
}

.dark-layout .Tab_wrapper {
  background-color: #283046;
  border: 1px solid gray;
}

.dark-layout .form-control {
  border: 1px solid #404656;
}

.rdt_Table {
  cursor: pointer;
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .header-navbar.floating-nav {
  width: calc(100vw - (100vw - 100%) - 0.4rem - 74px);
}

.dark-layout .gOjDfQ {
  background-color: #283046 !important;
}

/* .css-47z9mc-multiValue.select__multi-value {
  height: 20px;
  line-height: 12px;
}
.select__multi-value .select__multi-value__label{
  font-size: 10px;
} */
#cell-10-undefined span,
#cell-12-undefined span,
#cell-11-undefined span,
#cell-13-undefined span,
#cell-9-undefined span,
#cell-8-undefined span {
  margin-left: 5px;
}

/* #cell-1-undefined span,
#cell-2-undefined span,
#cell-4-undefined span,
#cell-5-undefined span {
  margin-left: -5px;
} */
.topic_page_description_field::-webkit-scrollbar {
  width: 7px;
  height: 4px;
}

.topic_page_description_field::-webkit-scrollbar-thumb {
  background: #adb5bd;
  border-radius: 3px;
  cursor: pointer;
}
