.modalBody {
  padding: 1.9rem 1.4rem !important;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileDetails1 {
  width: 100%;
  max-width: 400px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.profileLogo {
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
}

.profileDetails2 {
  width: 100%;
  max-width: 400px;
  display: grid;
  gap: 5px;
}

.listItems {
  display: grid;
  gap: 3px;
}

.labelInfo {
  font-weight: 400;
}

.detailInfo {
  font-size: 14px;
  font-weight: 700;
  color: #6e6b7b;
}

.technologyName {
  padding: 2px;
}

.technology-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 0;
}

.technology {
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 5px;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}

.line-break {
  margin: 0;
  border: none;
  border-top: 1px solid #ddd;
}

.myProfileDropDwn {
  width: 100% !important;
}

.userName {
  font-size: 22px;
  font-weight: 700;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
  text-align: center;
}
