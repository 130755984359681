/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.pointer {
  cursor: pointer;
}

.btn1 {
  border-color: #7367f0 !important;
  background-color: #7367f0 !important;
  text-align: center;
  cursor: pointer;
  margin: 5px;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
}

.icon-end {
  width: 100%;
  text-align: end;
  padding-right: 16px;
}

.link-btn {
  color: blue;
  cursor: pointer;
}

.modal-header {
  padding: 0.1rem 1.9rem;
  border-bottom: 0 solid #ebe9f1;
}
.text-height {
  margin-bottom: 0px;
  margin-left: -5px;
}

.bts {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.userImg {
  height: 60px;
  width: 70px;
  background: #cbc3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userImg p {
  font-size: 200%;
  color: #7367f0;
}

.card-body1 {
  padding: 0.5rem 1rem;
}

.card-body2 {
  padding: 0.2rem 1rem;
}

.readytoSub {
  padding: 0.786rem 0.5rem;
}

.textoverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}

.newTextoverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr {
  box-sizing: unset;
  padding-bottom: 16px !important;
  height: 48px;
}

.pl-8 {
  padding-left: 8px;
}
.pagination-row {
  align-items: center;
  justify-content: center;
}
.pagination-container {
  display: flex;
  gap: 1px;
  justify-content: center;
  align-items: center;
}
.chartLabelDot {
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
}
.technology-label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pie-chart-labels {
  padding: 0;
}
.col-sm-2 {
  padding: 0;
  align-self: center;
}
.pie-chart {
  padding: 0;
}
.pagination-arrow {
  border: none;
}
.left-arrow {
  transform: rotate(90deg);
  height: 15px;
}
.right-arrow {
  transform: rotate(270deg);
  height: 15px;
}
.chartLabelName {
  margin: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
div[disabled] {
  pointer-events: none;
  //opacity: 0.3;
}

.fixedCol {
  position: sticky;
  left: 0;
  z-index: 1;
}
#addTopicRating > div {
  width: 100%;
}
// .rdt_TableHeader{
//   position: sticky;
//   left: 0;
//   background: white;
//   z-index: 1;
// }

.upToCap {
  text-transform: capitalize;
}

.dropAction {
  padding: 0.65rem 0.28rem;
}

.google-signin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  position: relative;
  border: #cbc3e3 solid 1px;
  border-radius: 50px;
  text-align: center;
  margin: 15px 0;
  width: 100%;
  cursor: pointer;
  padding: 10px;
}
.google-signin-button:hover {
  background-color: rgb(236, 236, 247);
}
.google-signin-button img {
  position: absolute;
  left: 10px;
  height: 25px;
  width: 25px;
}

.modalWidth {
  overflow-wrap: anywhere !important;
  white-space: break-spaces !important;
}

.disableInput {
  pointer-events: none !important;
}

.ccColumnDiv {
  display: flex;
  flex-direction: column;
  margin: 5px 5px 5px 0;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px 5px 0;
    div {
      margin-left: 5px;
    }
  }
}

.trashCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailConfigSubmit {
  display: flex;
  // flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  Button {
    // margin-left: 10px;
    padding: 10px;
  }
}

.emailConfigAdd {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.spinner-border {
  animation: 0.75s linear infinite spinner-border-ltr !important;
}
