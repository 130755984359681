@import '../../base/pages/authentication.scss';

.auth-wrapper .auth-footer-btn .btn {
  padding: 0.555rem 0.6rem !important;
}

// ** Styles for ToastContent
.toast-content {
  display: flex;

  .avatar {
    margin-right: 1rem;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  .dismiss-icon {
    cursor: pointer;
  }
}
